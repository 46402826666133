<script lang="ts">
  import { openPosterModal } from "../stores/PosterModalStore";
  import type { Person } from "../stores/PosterModalStore";

  export let id: string;
  export let title: string;
  export let author: Person;
  export let coAuthors: Person[];
  export let thumbnailSrc: string;
  export let pdfSrc: string;
  export let imgSrc: string;
  export let twitter: string;
  export let facebook: string;
</script>

<div class="cardContainer">
  <div class="card" v-for="poster of posters3">
    <span />
    <span />
    <span />
    <span />
    <div class="content">
      <div id="contenedorImagen">
        <img
          class="prevPoster mx-auto"
          alt="Preview poster"
          src={thumbnailSrc}
        />
      </div>
      <h2 class="mt-16">{id}</h2>
      <h3>{title}</h3>
      <button
        id={`posterid_${id}`}
        on:click={() =>
          openPosterModal({
            author,
            coAuthors,
            title,
            pdfSrc,
            imgSrc,
            twitter,
            facebook,
          })}>Ver más</button
      >
    </div>
  </div>
</div>
