import { writable } from "svelte/store";
export const posterModalStore = writable({
    open: false,
    author: {
        email: "",
        name: "",
    },
    coAuthors: [],
    title: "",
    pdfSrc: "",
    imgSrc: "",
    twitter: "",
    facebook: "",
});
export function openPosterModal({ author, coAuthors, title, pdfSrc, imgSrc, twitter, facebook, }) {
    posterModalStore.set({
        open: true,
        author,
        coAuthors,
        title,
        pdfSrc,
        imgSrc,
        facebook,
        twitter,
    });
}
