const FirebaseFunctionsErrorCodes = [
    "ok",
    "cancelled",
    "unknown",
    "invalid-argument",
    "deadline-exceeded",
    "not-found",
    "already-exists",
    "permission-denied",
    "resource-exhausted",
    "failed-precondition",
    "aborted",
    "out-of-range",
    "unimplemented",
    "internal",
    "unavailable",
    "data-loss",
    "unauthenticated",
];
export function isFirebaseFunctionsError(error) {
    return FirebaseFunctionsErrorCodes.includes(getFirebaseErrorCode(error));
}
export function getFirebaseErrorCode(firebaseError) {
    return firebaseError === null || firebaseError === void 0 ? void 0 : firebaseError.code.split("/")[1];
}
