<script lang="ts">
  import Navbar from "./Navbar.svelte";
  import PosterModal from "./PosterModal/PosterModal.svelte";

  import Posters from "./Posters.svelte";
</script>

<main class="px-4">
  <Navbar />
  <Posters />
  <PosterModal />
</main>
