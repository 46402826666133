import { initializeApp } from "firebase/app";
import { httpsCallable, getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
const firebaseApp = initializeApp({
    apiKey: "AIzaSyBQTG_PW3OFg-HFtfALygUxzOI-KDlq0ls",
    authDomain: "congreso-quimica-verde.firebaseapp.com",
    projectId: "congreso-quimica-verde",
    storageBucket: "congreso-quimica-verde.appspot.com",
    messagingSenderId: "756953218123",
    appId: "1:756953218123:web:4b9bf0fa692c90043861ce",
    measurementId: "G-73YM9PBW29",
});
export const auth = getAuth();
export const functions = getFunctions();
export const analytics = getAnalytics(firebaseApp);
export const login = httpsCallable(functions, "login");
