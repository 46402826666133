<script lang="ts">
  export let className = "";
  export let disabled = false;
  export let type: "PRIMARY" | "SECONDARY" = "PRIMARY";
  export { className as class };
</script>

<button
  class={`
	${className}
	rounded-md px-4 py-2
	${disabled && "bg-gray-300"}
	${
    !disabled &&
    type === "PRIMARY" &&
    "bg-primary-500 hover:bg-primary-600 text-white transition-colors duration-150"
  }
	`}
  {disabled}
>
  <slot />
</button>
