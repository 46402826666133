<script lang="ts">
  import { posterModalStore } from "../../../stores/PosterModalStore";
</script>

<div class="w-full max-w-sm p-4">
  <h1 class=" text-primary-800">{$posterModalStore.title}</h1>
  <hr class="border-0 bg-gray-500/30 text-gray-500 h-px my-4" />
  <h1 class="text-gray-600 text-sm uppercase">Autor</h1>
  <h1 class="text-gray-800">{$posterModalStore.author.name}</h1>
  <small class="text-gray-700 break-words"
    >{$posterModalStore.author.email}</small
  >
  <hr class="border-0 bg-gray-500/30 text-gray-500 h-px my-2" />
  {#if $posterModalStore.coAuthors.length > 0}
    <h1 class="text-gray-600 text-sm uppercase">CO-AUTORES</h1>
    {#each $posterModalStore.coAuthors as coAuthor}
      <h1 class="text-gray-800">{coAuthor.name}</h1>
      <small class="text-gray-700 mb-4 break-words">{coAuthor.email}</small>
    {/each}
    <hr class="border-0 bg-gray-500/30 text-gray-500 h-px my-4" />
  {/if}
  {#if $posterModalStore.twitter || $posterModalStore.facebook}
    <h1 class="text-gray-600 text-sm uppercase">Redes Sociales</h1>
    {#if $posterModalStore.twitter}
      <div class="flex flex-row space-x-2 mb-2 items-center">
        <i class="fab fa-twitter-square text-primary-500 text-lg" />
        <h1 class="text-gray-800">{$posterModalStore.twitter}</h1>
      </div>
    {/if}
    {#if $posterModalStore.facebook}
      <div class="flex flex-row space-x-2 mb-2 items-center">
        <i class="fab fa-facebook-square text-primary-500 text-lg" />
        <h1 class="text-gray-800">{$posterModalStore.facebook}</h1>
      </div>
    {/if}
    <hr class="border-0 bg-gray-500/30 text-gray-500 h-px my-4" />
  {/if}
</div>
