<script lang="ts">
  import Poster from "../../components/Poster.svelte";
  import posters from "../../../public/assets/posters/posters.json";
  import Select from "svelte-select";
  import { getFileNameFromUrl } from "../../utilities/url";
  import { EmailAuthCredential } from "@firebase/auth";
  import { posterModalStore } from "../../stores/PosterModalStore";
  import Dialog from "@smui/dialog";
  import { onMount } from "svelte";
  import { selectedPosterFromAppStore } from "../../stores/SelectedPosterFromAppStore";

  const authorsSet = new Set();
  const axisSet = new Set();
  for (const poster of posters) {
    authorsSet.add(poster.Autor.Nombre);
    axisSet.add(poster.Eje);
  }
  const authors = Array.from(authorsSet);
  const axisList = Array.from(axisSet);

  let author: string;
  let axis: string;

  function onAuthorChange(newAuthor: string) {
    author = newAuthor;
  }
  function onAxisChange(newAxis: string) {
    axis = newAxis;
  }

  $: filteredPosters = posters.filter((poster) => {
    const wantsToFilterByAuthor = !!author;
    const wantsToFilterByAxis = !!axis;
    if (!wantsToFilterByAuthor && !wantsToFilterByAxis) return true;
    if (wantsToFilterByAuthor && !wantsToFilterByAxis) {
      return poster.Autor.Nombre === author;
    }
    if (!wantsToFilterByAuthor && wantsToFilterByAxis) {
      return poster.Eje === axis;
    }
    return poster.Autor.Nombre === author && poster.Eje === axis;
  });

  onMount(() => {
    if ($selectedPosterFromAppStore.posterId) {
      const viewPosterBtn = document.querySelector(
        `#posterid_${$selectedPosterFromAppStore.posterId}`
      ) as HTMLButtonElement;
      viewPosterBtn.click();
    }
  });
</script>

<section class="container">
  <h1 class="mb-2 text-md w-full">
    Filtros, busca póster por <span class="text-primary-600">autor</span> o
    agrupalos por <span class="text-primary-600">eje</span>
  </h1>
  <div class="themed flex flex-col md:flex-row w-full">
    <Select
      containerClasses="w-full z-[3]"
      on:select={(e) => onAuthorChange(e.detail.value)}
      on:clear={() => onAuthorChange("")}
      noOptionsMessage="Ningún autor coincide con tu búsqueda"
      placeholder="Autor"
      items={authors}
    />
    <div class="py-2 md:py-0 md:px-4" />
    <Select
      containerClasses="w-full z-[2]"
      on:select={(e) => onAxisChange(e.detail.value)}
      on:clear={() => onAxisChange("")}
      noOptionsMessage="No se encontró el eje"
      placeholder="Eje"
      items={axisList}
    />
  </div>
  {#if filteredPosters.length > 0}
    <div class="grid md:grid-cols-2 lg:grid-cols-3 container justify-center">
      {#each filteredPosters as poster}
        <Poster
          id={poster.Autor.Folio}
          author={{
            email: poster.Autor.Email,
            name: poster.Autor.Nombre,
          }}
          coAuthors={poster.CoAutores.map(({ Email: email, Nombre: name }) => ({
            email,
            name,
          }))}
          title={poster.Titulo}
          thumbnailSrc={`/assets/posters/thumbnails/${getFileNameFromUrl(
            poster.Archivo_Jpg
          )}`}
          pdfSrc={`/assets/posters/pdfs/${getFileNameFromUrl(
            poster.Archivo_Pdf
          )}`}
          imgSrc={`/assets/posters/images/${getFileNameFromUrl(
            poster.Archivo_Jpg
          )}`}
          twitter={poster.Twitter}
          facebook={poster.Facebook}
        />
      {/each}
    </div>
  {:else}
    <h1 class="text-gray-700 w-full text-center mt-4">
      No se encontró ningún póster con ese autor y/o eje
    </h1>
  {/if}
</section>

<style>
  .themed {
    --border: 2px solid var(--primary-200);
    --borderFocusColor: 2px solid red;
    --borderHoverColor: 2px solid green;
    --borderRadius: 7px;
    --itemHoverBG: var(--primary-50);
    --itemIsActiveBG: var(--primary-500);
  }
</style>
