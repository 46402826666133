<script lang="ts">
  import { useNavigate, useLocation } from "svelte-navigator";
  import { loginWithEmailAndCode } from "../api/login";
  import Button from "../components/Button.svelte";
  import TextField from "../components/TextField.svelte";
  import type { AsyncStatus } from "../utilTypes";

  const navigate = useNavigate();
  const location = useLocation();

  let email: string;
  let code: string;
  let status: AsyncStatus = "NOT_DISPATCHED";
  let errorMsg: string = "";

  async function handleSubmit() {
    status = "LOADING";
    errorMsg = "";
    const error = await loginWithEmailAndCode({ email, codigo: code });
    if (error !== null) {
      errorMsg = error?.message;
      status = "ERROR";
      return;
    }
    const from = ($location.state && $location.state.from) || "/";
    navigate(from, { replace: true });
    status = "SUCCESS";
  }
</script>

<div>
  <div class="wrapper">
    <div class="main">
      <img src="/assets/img/logo.png" alt="" />
      <h1>Presentación de pósters</h1>
      <form
        class="w-[80vw] sm:w-1/2"
        on:submit|preventDefault={handleSubmit}
        id="formLogin"
      >
        <div id="login">
          <!-- <input bind:value={email} type="email" id="email" required /> -->
          <TextField
            bind:value={email}
            name="Correo"
            type="password"
            errors={[]}
          />
          <TextField
            bind:value={code}
            name="Número de registro de asistente"
            type="password"
            errors={[]}
          />
          <!-- <button disabled={status === "LOADING"} id="btnLogin">
            <h2>Ingresar</h2>
          </button> -->
          <Button disabled={status === "LOADING"} class="mt-4"
            >{status === "LOADING" ? "Ingresando..." : "Ingresar"}</Button
          >
          <p class="txtVerde" id="mensajeCargando" />
          <p class="txtRojo" id="mensajeError" />
        </div>
        <p class="text-red-500 text-center">{errorMsg}</p>
      </form>
    </div>
    <div class="imagen" />
  </div>
</div>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Rubik:wght@500&display=swap");
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }

  /* Etiquetas */

  h1 {
    font-family: "Rubik", sans-serif;
    margin-top: 20px;
    color: #75b141;
  }

  /* Clases */

  .wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
  }

  .main {
    width: 50%;
    height: 100vh;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imagen {
    width: 50%;
    height: 100vh;
    background-color: whitesmoke;
    background-image: url(/assets/img/planeta.png);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 250%;
  }

  .txtRojo {
    color: red;
    text-align: center;
    margin-top: 5px;
  }

  .txtVerde {
    color: #75b141;
    text-align: center;
    margin-top: 5px;
  }

  /* Id's */

  #login {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
  }

  /* Responsive desing */

  /* Small  */

  @media (max-width: 460px) {
    .main {
      width: 100%;
    }
    .main img {
      width: 70%;
    }
    .imagen {
      width: 0%;
    }
  }

  /* Medium */

  @media (min-width: 461px) and (max-width: 1007px) {
    .main {
      width: 100%;
    }
    .imagen {
      width: 0%;
    }
  }
</style>
