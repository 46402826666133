<script lang="ts">
  import { useNavigate, useLocation } from "svelte-navigator";

  export let loggedIn: boolean;

  const navigate = useNavigate();
  const location = useLocation();

  $: if (loggedIn) {
    navigate("/");
  }
</script>

{#if !loggedIn}
  <slot />
{/if}
