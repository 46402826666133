<script lang="ts">
  import { Router, Route, Link } from "svelte-navigator";
  import Auth from "./components/Auth.svelte";
  import OnlyPublicRoute from "./components/OnlyPublicRoute.svelte";
  import PrivateRoute from "./components/PrivateRoute.svelte";
  import Home from "./pages/Home/Home.svelte";
  import Login from "./pages/Login.svelte";
  import Loading from "./pages/Loading.svelte";
  import JoinFromApp from "./pages/JoinFromApp.svelte";
</script>

<Router>
  <Auth let:loggedIn let:user let:loadingUser>
    {#if loadingUser}
      <Loading />
    {:else}
      <PrivateRoute {loggedIn} path="/">
        <Home />
      </PrivateRoute>
      <OnlyPublicRoute {loggedIn} path="/login">
        <Login />
      </OnlyPublicRoute>
      <Route {loggedIn} path="/ingresar">
        <JoinFromApp {loggedIn} />
      </Route>
    {/if}
  </Auth>
</Router>
