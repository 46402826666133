<script lang="ts">
  import { signOut } from "@firebase/auth";
  import { auth } from "../../firebase";

  function logout() {
    signOut(auth);
  }
</script>

<div class="w-full h-24 py-4 container flex justify-between">
  <img class="h-full" src="/assets/img/logo.png" alt="logo" />
  <button on:click={logout} class="text-gray-600"
    ><span class="text-primary-600"><i class="fas fa-sign-out-alt" /></span>
    Cerrar sesión
  </button>
</div>
