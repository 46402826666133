<script lang="ts">
  import Dialog from "@smui/dialog";
  import Drawer, { Content, Scrim, AppContent } from "@smui/drawer";
  import Zoom from "svelte-zoom";
  import { isDev } from "../../../env";
  import { posterModalStore } from "../../../stores/PosterModalStore";
  import { breakWord } from "../../../utilities/breakWord";
  import PosterInfo from "./PosterInfo.svelte";

  const isMobile = window.innerWidth < 640;
  let isInfoDrawerOpen = false;
  function toggleInfoDrawer() {
    isInfoDrawerOpen = !isInfoDrawerOpen;
  }
</script>

<Dialog
  container$class="w-screen max-w-screen"
  surface$class="w-screen !max-w-[80vw]"
  class="w-screen"
  open={$posterModalStore.open}
>
  <div class="flex relative overflow-hidden z-0">
    {#if isMobile}
      <Drawer
        class="!w-5/6"
        variant="modal"
        fixed={false}
        bind:open={isInfoDrawerOpen}
      >
        <div class="overflow-auto p-2 sm:p-4 flex flex-col">
          <PosterInfo />
          <button
            on:click={toggleInfoDrawer}
            class="text-xl text-gray-700 focus:outline-none"
          >
            <i class="fas fa-angle-double-left" />
          </button>
        </div>
      </Drawer>
      <Scrim fixed={false} />
    {/if}
    <div class="w-full sm:h-[90vh] flex-grow sm:flex sm:flex-row">
      {#if isMobile}
        <div class="flex flex-row p-4">
          <h1 class="text-md">
            {breakWord($posterModalStore.title, 70)}
          </h1>
          <button
            on:click={toggleInfoDrawer}
            class="text-primary-600 ml-4 text-xl focus:outline-none"
          >
            <i class="fas fa-info-circle" />
          </button>
        </div>
      {:else}
        <PosterInfo />
      {/if}
      {#if isMobile}
        <img src={$posterModalStore.imgSrc} alt="Póster" class="w-full" />
      {:else}
        <iframe
          class="w-full h-full"
          title="Póster"
          src={$posterModalStore.pdfSrc}
        />
      {/if}
      <!-- <iframe
        class="w-full h-full"
        title="Póster"
        src={isDev
          ? $posterModalStore.pdfSrc
          : `https://docs.google.com/gview?url=${`${window.location.href}assets/posters/pdfs/14_1629388428_crtl_pdf.pdf`}&embedded=true`}
      /> -->
    </div>
  </div>
</Dialog>
