<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let name: string;
  export const type: string = "text";
  export let placeholder = "";
  export let errors: string[] = [];

  export let value = "";

  function handleChange(newValue: string) {
    value = newValue;
    // dispatch("value", newValue);
  }
</script>

<div>
  <label class="block text-sm font-medium text-gray-700" for={`input-${name}`}
    >{name}</label
  >
  <input
    class="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-4 py-2"
    on:change={(e) => handleChange(e.currentTarget.value)}
    {type}
    {name}
    id={`input-${name}`}
    {placeholder}
  />
  <p class="text-red-500 text-sm mt-1">{errors.join(". ")}</p>
</div>
