import { auth, login } from "../firebase";
import "firebase/functions";
import { getFirebaseErrorCode, isFirebaseFunctionsError } from "../errors";
import { signInWithCustomToken } from "@firebase/auth";
const LoginErrorBag = {
    unauthenticated: "Las credenciales no son validas",
    "invalid-argument": "Datos incorrectos",
    unknown: "Algo salio mal",
};
export async function loginWithEmailAndCode(credentials) {
    try {
        const response = await login(credentials);
        const { token } = response.data;
        await signInWithCustomToken(auth, token);
        return null;
    }
    catch (error) {
        console.log(error.code);
        if (isFirebaseFunctionsError(error)) {
            return new Error(LoginErrorBag[getFirebaseErrorCode(error)]);
        }
        return new Error("Algo salio mal");
    }
}
