<script lang="ts">
  import { onAuthStateChanged } from "firebase/auth";
  import type { User } from "firebase/auth";
  import { auth } from "../firebase";

  let user: User | null = null;
  let loadingUser = true;

  onAuthStateChanged(auth, (_user) => {
    console.log(`Current user is ${_user}`);
    loadingUser = false;
    user = _user;
  });

  $: loggedIn = user !== null;
</script>

<div>
  <slot {user} {loggedIn} {loadingUser} />
</div>
