<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { loginWithEmailAndCode } from "../api/login";
  import { selectedPosterFromAppStore } from "../stores/SelectedPosterFromAppStore";

  export let loggedIn: boolean;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const email = params?.email;
  const codigo = params?.codigo;
  const poster = params?.folio;

  if (loggedIn) {
    if (poster) {
      $selectedPosterFromAppStore.posterId = poster;
    }
    navigate("/");
  }

  if (!email || !codigo) navigate("/login");

  onMount(() => {
    loginWithEmailAndCode({
      email,
      codigo,
    }).then((err) => {
      console.log("err", err);
      if (err !== null) {
        navigate("/login");
      }
      if (poster) {
        $selectedPosterFromAppStore.posterId = poster;
      }
      navigate("/");
    });
  });
</script>

{#if !loggedIn}
  <main class="text-2xl grid place-content-center w-screen h-screen">
    Iniciando sesión...
  </main>
{/if}
