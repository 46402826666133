<script lang="ts">
  import { Route } from "svelte-navigator";
  import PrivateRouteGuard from "./PrivateRouteGuard.svelte";

  export let path: string;
  export let loggedIn: boolean;
</script>

<Route {path} let:params let:location let:navigate>
  <PrivateRouteGuard {loggedIn}>
    <slot {params} {location} {navigate} />
  </PrivateRouteGuard>
</Route>
