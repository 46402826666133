<script lang="ts">
  import { onAuthStateChanged } from "@firebase/auth";
  import { onMount } from "svelte";
  import { useNavigate, useLocation } from "svelte-navigator";

  export let loggedIn: boolean;

  const navigate = useNavigate();
  const location = useLocation();

  $: if (!loggedIn) {
    navigate("/login", {
      state: { from: $location.pathname },
      replace: true,
    });
  }
</script>

{#if loggedIn}
  <slot />
{/if}
